import "../stylesheets/application.css"

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import '@rails/ujs'
//
// import ActiveStorage from '@rails/activestorage'
//
// // Import all channels.
// const channels = import.meta.glob('./**/*_channel.js', { eager: true })
//
// ActiveStorage.start()

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "../config/i18n"

import { Application } from "@hotwired/stimulus"
import { Modal, Toggle } from "tailwindcss-stimulus-components"

import CookieBanner from "../controllers/cookie_banner_controller"

// Stimulus controllers
window.Stimulus = Application.start()
window.Stimulus.register('cookie-banner', CookieBanner)
window.Stimulus.register('modal', Modal)
window.Stimulus.register('toggle', Toggle)
